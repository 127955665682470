@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";

.RichText {
	font-weight: var(--font-weight--normal);

	h1 {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--base);
		@include heading--4xl;
	}

	h2 {
		margin-top: var(--spacing--3xl);
		margin-bottom: var(--spacing--base);
		@include heading--2xl;
	}

	p {
		@include paragraph;
		margin-bottom: var(--spacing--base);
	}

	ul {
		@include paragraph;
		padding-left: 2rem;
		margin-bottom: var(--spacing--base);
	}

	ol {
		@include paragraph;
		padding-left: 2rem;
		margin-bottom: var(--spacing--base);
	}

	a {
		color: currentColor;
		// font-weight: var(--font-weight--normal);

		text-decoration: underline;
		&:hover {
			text-decoration: underline;
		}
	}

	img {
		width: 100%;
		height: auto;
		padding-block: 0.5rem;
	}

	:global(.rte-linkButton),
	:global(a .rte-linkButton),
	:global(.rte-linkButton a),
	:global(a.rte-linkButton),
	:global(.rte-readMoreButton),
	:global(a .rte-readMoreButton),
	:global(.rte-readMoreButton a),
	:global(a.rte-readMoreButton) 
	{
		@include button;
		border-color: currentColor;
		background: transparent;
		margin: var(--spacing--base) 0;
		width: fit-content;
		display: block;
	}
}

.RichText___bigText {
	p,
	li,
	ul,
	ol {
		@include body--lg-lig;
	}
}

.RichText___truncateText {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
