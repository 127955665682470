@import "ui/styles/2-tools/tools.media-query.scss";
@import "ui/styles/2-tools/tools.aspect-ratio.scss";
@import "ui/styles/2-tools/tools.action";
@import "ui/styles/2-tools/tools.typography-mixins.scss";
@import "ui/styles/2-tools/tools.colors.scss";

.HeroCard {
	@include coloredComponent;

	position: relative;
	height: 100%;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}

.HeroCard_container {
	position: relative;
}

.HeroCard_heading {
	text-align: left;
	margin-bottom: var(--spacing--4xl);

	.HeroCard___noOverlay & {
		margin-bottom: 0;
	}

	@include mq("md") {
		margin-bottom: var(--spacing--2xl);

		.HeroCard___noOverlay & {
			margin-bottom: 0;
		}
	}

	@include mq("lg") {
		margin-bottom: var(--spacing--4xl);

		.HeroCard___noOverlay & {
			margin-bottom: 0;
		}
	}
}
// This <span> tag is re-generated by javascript and .split-text class is added to it
.HeroCard_kickerInner {
	opacity: 0;
}

.HeroCard_media {
	transition: padding 0.3s ease;
}

.HeroCard_mediabox {
	@include aspect-ratio(1, 1.75);

	@include mq("sm") {
		@include aspect-ratio(1, 1);
	}

	@include mq("md") {
		@include aspect-ratio(1.25, 1);
	}

	@include mq("xl") {
		@include aspect-ratio(1.95, 1);
	}
}

.HeroCard_kicker {
	margin-bottom: var(--spacing--sm);

	@include mq("lg") {
		margin-bottom: var(--spacing--xl);
	}
}

.HeroCard_textbox {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: auto;
	display: flex;
	justify-content: center;
	flex-direction: column;

	.HeroCard___hasBgImage:not(.HeroCard___noOverlay) & {
		color: var(--color-white);
	}

	.HeroCard___noOverlay & {
		position: relative;
		height: 100%;
		padding-top: var(--spacing--10xl);
		max-width: initial;
		margin-bottom: var(--spacing--2xl);
	}

	.HeroCard:not(.HeroCard___hasBgImage) & {
		position: relative;
		height: 100%;
		margin: 0;
		padding-top: var(--spacing--10xl);
	}
}

.HeroCard_textboxInner {
	padding-top: var(--spacing-10xl);

	@include mq("md") {
		padding-top: 0;
	}
}

.HeroCard_sideTextWrapper {
	@include mq("md", "max") {
		order: 2;
	}
}

.HeroCard_manchetWrapper {
	@include mq("md", "max") {
		order: 1;
	}
}

.HeroCard_sideText,
.HeroCard_manchet {
	text-align: left;
	font-weight: var(--font-weight--normal);
	margin: var(--spacing--2xl) 0;

	@include mq("md") {
		margin: var(--spacing--4xl) 0;

		&___noImg {
			margin: 0 0 var(--spacing--10xl) 0;
		}
	}
}

.HeroCard_sideText {
	a {
		@include action;
		@include paragraph;
		@include coloredComponentText;

		text-decoration: underline;
		display: block;
		margin-bottom: var(--spacing--xs);
	}
}

.scrolled {
	.HeroCard_media {
		padding: 0 var(--spacing--sm);
	}

	@include mq("sm") {
		.HeroCard_media {
			padding: 0 var(--spacing--xl);
		}
	}

	@include mq("md") {
		.HeroCard_media {
			padding: 0 var(--spacing--2xl);
		}
	}
}

// Theme: Kogp
[data-theme="kogp"] {
	.HeroCard_kicker {
		span {
			font-weight: var(--font-weight--extrablack);
		}
	}
}
